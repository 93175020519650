import React, { useState, useEffect } from 'react';
import './Files.css';
import Post from './Post.js';
import { getSavedPosts, unsavePost } from './utils/localStorage.js';

const Files = () => {
    const [activeTab, setActiveTab] = useState('posts');
    const [savedPosts, setSavedPosts] = useState([]);
    const [visiblePosts, setVisiblePosts] = useState([]);

    // Function to refresh posts from localStorage
    const refreshPosts = () => {
        console.log('Refreshing posts');
        const posts = getSavedPosts();
        console.log('Posts from localStorage:', posts);
        setSavedPosts(posts);
    };

    // Initial load
    useEffect(() => {
        refreshPosts();
    }, []);

    useEffect(() => {
        console.log('savedPosts updated:', savedPosts);
        setVisiblePosts(savedPosts);
    }, [savedPosts]);

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    const handleUnsave = (postId) => {
        unsavePost(postId);
        refreshPosts();
    };

    return (
        <div className="dashboard-container">
            <div className="tabs">
                <button 
                    className={`tab ${activeTab === 'posts' ? 'active' : ''}`}
                    onClick={() => handleTabChange('posts')}
                >
                    Posts
                </button>
                <button 
                    className={`tab ${activeTab === 'projects' ? 'active' : ''}`}
                    onClick={() => handleTabChange('projects')}
                >
                    Comments
                    <span className="coming-soon-indicator">Coming Soon</span>
                </button>
                <button 
                    className={`tab ${activeTab === 'skills' ? 'active' : ''}`}
                    onClick={() => handleTabChange('skills')}
                >
                    Solutions
                    <span className="coming-soon-indicator">Coming Soon</span>
                </button>
            </div>
            
            <div className="content-section">
                {activeTab === 'posts' && (
                    <>
                        {visiblePosts && visiblePosts.length > 0 ? (
                            <div className="saved-posts-container">
                                {visiblePosts.map(post => (
                                    <Post
                                        key={post.id}
                                        postId={post.id}
                                        username={post.username}
                                        profession={post.profession}
                                        timestamp={post.timestamp}
                                        content={post.content}
                                        actionTag={post.actionTag}
                                        onUnsave={handleUnsave}
                                    />
                                ))}
                            </div>
                        ) : (
                            <div className="no-saved-posts">
                                <p>No saved posts yet</p>
                            </div>
                        )}
                    </>
                )}
                
                {activeTab === 'projects' && (
                    <div className="underdevelopment-indicator">
                        Comments feature is under development
                    </div>
                )}

                {activeTab === 'skills' && (
                    <div className="underdevelopment-indicator">
                        Solutions feature is under development
                    </div>
                )}
            </div>
        </div>
    );
};

export default Files;