import React, { useState, useEffect, useRef, useCallback } from 'react';
import './ChatPanel.css';
import { IconArrowLeft, IconSend, IconLoader2 } from '@tabler/icons-react';

function ChatPanel() {
    const [selectedUser, setSelectedUser] = useState(null);
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [isTyping, setIsTyping] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [hasMore, setHasMore] = useState(true);
    
    const inputRef = useRef(null);
    const chatEndRef = useRef(null);
    const chatContentRef = useRef(null);

    const users = [
        { id: 1, name: 'Razack', avatar: 'https://img.icons8.com/color/48/000000/user-male-circle.png', lastMessage: 'Hey there!', timestamp: 'Nov 13, 2024, 2:00 PM', unread: true },
        { id: 2, name: 'Omid', avatar: 'https://img.icons8.com/color/48/000000/user-male-circle.png', lastMessage: 'How are you?', timestamp: 'Nov 12, 2024, 5:30 PM', unread: false },
    ];

    // Load messages with pagination
    const loadMessages = useCallback(async () => {
        if (isLoading || !hasMore) return;
        
        try {
            setIsLoading(true);
            setError(null);
            
            // Simulated API call - replace with actual API
            const newMessages = [...messages]; // Replace with API call
            
            setMessages(prev => [...prev, ...newMessages]);
            setHasMore(newMessages.length > 0);
        } catch (err) {
            setError('Failed to load messages. Please try again.');
            console.error('Error loading messages:', err);
        } finally {
            setIsLoading(false);
        }
    }, [isLoading, hasMore, messages]);

    // Handle scroll for infinite loading
    const handleScroll = useCallback(() => {
        if (!chatContentRef.current) return;
        
        const { scrollTop, scrollHeight, clientHeight } = chatContentRef.current;
        if (scrollHeight - scrollTop <= clientHeight * 1.5) {
            loadMessages();
        }
    }, [loadMessages]);

    // Scroll to bottom when new message is added
    useEffect(() => {
        chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [messages]);

    // Add scroll listener for infinite loading
    useEffect(() => {
        const chatContent = chatContentRef.current;
        if (chatContent) {
            chatContent.addEventListener('scroll', handleScroll);
            return () => chatContent.removeEventListener('scroll', handleScroll);
        }
    }, [handleScroll]);

    // Handle message sending
    const sendMessage = async () => {
        if (!newMessage.trim()) return;
        
        // Create message object before try block so it's accessible in catch
        const messageToSend = {
            id: Date.now(),
            sender: 'me',
            text: newMessage,
            timestamp: new Date().toLocaleString('en-US', {
                month: 'short',
                day: 'numeric',
                year: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                hour12: true,
            }),
            status: 'sending'
        };
        
        try {
            setIsLoading(true);
            setError(null);
            
            // Add message to state immediately with 'sending' status
            setMessages(prev => [...prev, messageToSend]);
            setNewMessage('');
            setIsTyping(false);

            // Simulated API call - replace with actual API
            await new Promise(resolve => setTimeout(resolve, 1000));
            
            // Update message status to sent
            setMessages(prev => 
                prev.map(msg => 
                    msg.id === messageToSend.id 
                        ? { ...msg, status: 'sent' } 
                        : msg
                )
            );
        } catch (err) {
            setError('Failed to send message. Please try again.');
            console.error('Error sending message:', err);
            
            // Update message status to failed
            setMessages(prev => 
                prev.map(msg => 
                    msg.id === messageToSend.id 
                        ? { ...msg, status: 'failed' } 
                        : msg
                )
            );
        } finally {
            setIsLoading(false);
        }
    };

    // Handle input changes
    const handleMessageInput = (e) => {
        setNewMessage(e.target.value);
        setIsTyping(e.target.value.trim() !== '');
        
        // Auto-resize textarea
        e.target.style.height = 'auto';
        e.target.style.height = `${Math.min(e.target.scrollHeight, 200)}px`;
    };

    // Handle key press
    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            sendMessage();
        }
    };

    return (
        <div className="chat-panel" role="complementary" aria-label="Chat panel">
            {selectedUser ? (
                <div className="chat" role="region" aria-label={`Chat with ${selectedUser.name}`}>
                    {/* Chat header */}
                    <div className="chat-header">
                        <button 
                            className="back-button3" 
                            onClick={() => setSelectedUser(null)}
                            aria-label="Go back to user list"
                        >
                            <IconArrowLeft />
                        </button>
                        <div className="chat-header__info">
                            <img 
                                src={selectedUser.avatar} 
                                alt="" 
                                className="user-avatar" 
                                aria-hidden="true" 
                            />
                            <h3 className="chat-header__title">{selectedUser.name}</h3>
                        </div>
                    </div>

                    {/* Chat content */}
                    <div 
                        className="chat-content" 
                        ref={chatContentRef}
                        role="log"
                        aria-live="polite"
                    >
                        {error && (
                            <div className="chat-error" role="alert">
                                {error}
                            </div>
                        )}
                        
                        {isLoading && (
                            <div className="chat-loading">
                                <IconLoader2 className="loading-spinner" />
                                Loading messages...
                            </div>
                        )}

                        {messages.map((msg) => (
                            <div 
                                key={msg.id} 
                                className={`chat-message ${msg.sender} ${msg.status}`}
                                role="article"
                            >
                                <span className="chat-message__text">{msg.text}</span>
                                <span className="chat-message__timestamp">
                                    {msg.timestamp}
                                    {msg.status === 'failed' && ' • Failed to send'}
                                </span>
                            </div>
                        ))}

                        {isTyping && (
                            <div className="chat-message typing" aria-live="polite">
                                <div className="typing-indicator">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </div>
                        )}
                        
                        <div ref={chatEndRef} />
                    </div>

                    {/* Chat input */}
                    <div className="chat-input">
                        <textarea
                            ref={inputRef}
                            value={newMessage}
                            onChange={handleMessageInput}
                            onKeyDown={handleKeyDown}
                            placeholder="Type your message..."
                            className="input"
                            rows="1"
                            aria-label="Message input"
                        />
                        <button 
                            className="send-button" 
                            onClick={sendMessage} 
                            disabled={!newMessage.trim() || isLoading}
                            aria-label="Send message"
                        >
                            <IconSend />
                        </button>
                    </div>
                </div>
            ) : (
                <UserPanel users={users} onSelectUser={setSelectedUser} />
            )}
        </div>
    );
}

// Separate UserPanel component
function UserPanel({ users, onSelectUser }) {
    return (
        <div className="user-panel" role="region" aria-label="User list">
            <div className="user-panel__header">
                <h3>Messages</h3>
            </div>
            <ul className="user-panel__list">
                {users.map((user) => (
                    <li 
                        key={user.id} 
                        className="user-panel__item" 
                        onClick={() => onSelectUser(user)}
                        role="button"
                        tabIndex={0}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter' || e.key === ' ') {
                                e.preventDefault();
                                onSelectUser(user);
                            }
                        }}
                    >
                        <img src={user.avatar} alt="" className="user-avatar" aria-hidden="true" />
                        <div className="user-info">
                            <span className="user-name">{user.name}</span>
                            <span className="last-message">{user.lastMessage}</span>
                            <span className="timestamp">{user.timestamp}</span>
                        </div>
                        {user.unread && (
                            <span className="unread-badge" role="status" aria-label="Unread message">
                                •
                            </span>
                        )}
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default ChatPanel;