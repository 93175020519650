const Input = ({ icon: Icon, ...props }) => {
  return (
    <div className="relative mb-4">
      <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
        <Icon className="w-5 h-5 text-green-400" />
      </div>
      <input 
        {...props}
        className="w-full pl-10 pr-3 py-2.5 bg-gray-800 bg-opacity-50 rounded-lg 
        border border-gray-700 focus:border-green-400 focus:ring-2 
        focus:ring-green-400 text-white placeholder-gray-400 
        transition duration-200 outline-none"
      />
    </div>
  );
};

export default Input;
