import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import './Sidebar.css';
import SidebarOption from './SidebarOption';
import { IconClipboardList, IconHeartHandshake, IconUsersGroup, IconFiles, IconLogout2 } from "@tabler/icons-react";

const UserProfile = ({ user }) => {
  const initial = user.name.charAt(0);

  return (
    <div className="sidebar-profile">
      <NavLink
        to="/profile"
        className={({ isActive }) => `profile-link ${isActive ? "active" : ""}`}
      >
        <div className="profile-content">
          <div className="profile-avatar">
            <span>{initial}</span>
          </div>
          <div className="profile-info">
            <h3>{user.name}</h3>
            <p>{user.profession}</p>
          </div>
        </div>
      </NavLink>
    </div>
  );
};

function Sidebar() {
  const navigate = useNavigate();
  const user = {
    name: "Raz Conteh",
    profession: "Cancer Researcher"
  };

  const handleLogout = () => {
    console.log('Logout clicked');
  };

  return (
    <div className="sidebar">
      <UserProfile user={user} />
      
      <div className="sidebar-menu">
        <div className="sidebarOption contributions coming-soon">
          <SidebarOption
            Icon={IconHeartHandshake}
            text="Contributions"
            iconColor="#4CAF50"
            comingSoon={true}
          />
        </div>
        <div className="sidebarOption followed-projects coming-soon">
          <SidebarOption
            Icon={IconUsersGroup}
            text="Followed Projects"
            iconColor="#2196F3"
            comingSoon={true}
          />
        </div>
        <div className="sidebarOption your-projects coming-soon">
          <SidebarOption
            Icon={IconFiles}
            text="Your Projects"
            iconColor="#F44336"
            comingSoon={true}
          />
        </div>
        <NavLink to="/files" className={({ isActive }) => `sidebarOption files ${isActive ? "sidebarOption--active" : ""}`}>
          <SidebarOption
            Icon={IconClipboardList}
            text="Files"
            iconColor="#FF9800"
          />
        </NavLink>
      </div>

      <div className="sidebar-logout">
        <div className="sidebarOption logout" onClick={handleLogout}>
          <SidebarOption
            Icon={IconLogout2}
            text="Logout"
            iconColor="#666"
          />
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
