import { useState, useCallback } from "react";
import { motion } from "framer-motion";
import Input from "./input.jsx";
import { User, Mail, Lock, Eye, EyeOff } from "lucide-react";
import { Link } from "react-router-dom";
import { validateName, validateEmail } from './utils/validation.js';
import {  Loader} from "lucide-react";

const COMMON_PATTERNS = [
    'password', '123456', 'qwerty', 'admin', 'letmein', 'welcome',
    'abc123', '111111', '123123', 'dragon', 'baseball', 'football'
];

const getStrengthColor = (index) => {
    const colors = {
        0: 'bg-red-500',           // Very Weak
        1: 'bg-red-400',           // Very Weak
        2: 'bg-orange-400',        // Weak
        3: 'bg-yellow-400',        // Fair
        4: 'bg-green-400',         // Good
        5: 'bg-emerald-500'        // Strong
    };
    return colors[index] || 'bg-emerald-500';
};

const getPasswordStrength = (password) => {
    if (!password) return { 
        strength: 0, 
        message: '', 
        suggestions: ['Please enter a password']
    };

    let strength = 0;
    const suggestions = [];

    // Base length check (8+ characters)
    if (password.length >= 8) {
        strength += 1;
    } else {
        suggestions.push('Use at least 8 characters');
    }

    // Extra length bonus (12+ characters)
    if (password.length >= 12) {
        strength += 1;
    }

    // Character diversity checks
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumbers = /[0-9]/.test(password);
    const hasSpecialChars = /[^A-Za-z0-9]/.test(password);

    if (hasUpperCase) strength += 1;
    if (hasLowerCase) strength += 1;
    if (hasNumbers) strength += 1;
    if (hasSpecialChars) strength += 1;

    // Add suggestions based on missing criteria
    if (!hasUpperCase) suggestions.push('Add uppercase letters');
    if (!hasLowerCase) suggestions.push('Add lowercase letters');
    if (!hasNumbers) suggestions.push('Add numbers');
    if (!hasSpecialChars) suggestions.push('Add special characters');

    // Check for repeated characters (3 or more)
    if (/(.)\1{2,}/.test(password)) {
        strength -= 1;
        suggestions.push('Avoid repeated characters');
    }

    // Check for common patterns
    if (COMMON_PATTERNS.some(pattern => 
        password.toLowerCase().includes(pattern.toLowerCase()))) {
        strength -= 1;
        suggestions.push('Avoid common password patterns');
    }

    // Ensure strength doesn't go below 0
    strength = Math.max(0, strength);

    // Define strength levels
    const strengthLevels = [
        { 
            threshold: 0, 
            message: 'Very Weak', 
            class: 'text-red-500' 
        },
        { 
            threshold: 2, 
            message: 'Weak', 
            class: 'text-orange-400' 
        },
        { 
            threshold: 3, 
            message: 'Fair', 
            class: 'text-yellow-400' 
        },
        { 
            threshold: 4, 
            message: 'Good', 
            class: 'text-green-400' 
        },
        { 
            threshold: 5, 
            message: 'Strong', 
            class: 'text-emerald-500' 
        },
        { 
            threshold: 6, 
            message: 'Very Strong', 
            class: 'text-emerald-500' 
        }
    ];

    // Find appropriate strength level
    const strengthLevel = strengthLevels
        .reverse()
        .find(level => strength >= level.threshold);

    return {
        strength,
        message: strengthLevel.message,
        suggestions: suggestions,
        class: strengthLevel.class,
        percentage: Math.min((strength / 6) * 100, 100)
    };
};

const SignUpPage = ({ isDarkMode }) => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        password: '',
        confirmPassword: ''
    });

    const [formErrors, setFormErrors] = useState({
        name: '',
        email: '',
        password: '',
        confirmPassword: ''
    });

    const [showPassword, setShowPassword] = useState({
        password: false,
        confirmPassword: false
    });

    // Add a new state to track which fields have been touched
    const [touchedFields, setTouchedFields] = useState({
        name: false,
        email: false,
        password: false,
        confirmPassword: false
    });

    // Add loading state
    const [isLoading, setIsLoading] = useState(false);

    // Enhanced handleChange with real-time validation
    const handleChange = (e) => {
        const { name, value } = e.target;
        
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));

        // Mark field as touched
        setTouchedFields(prev => ({
            ...prev,
            [name]: true
        }));

        // Only validate if the field has been touched and has a value
        switch (name) {
            case 'name': {
                if (value) {
                    const validation = validateName(value);
                    setFormErrors(prev => ({
                        ...prev,
                        name: validation.isValid ? '' : validation.error
                    }));
                } else {
                    setFormErrors(prev => ({
                        ...prev,
                        name: ''
                    }));
                }
                break;
            }
            case 'email': {
                if (value) {
                    const validation = validateEmail(value);
                    setFormErrors(prev => ({
                        ...prev,
                        email: validation.isValid ? '' : validation.error
                    }));
                } else {
                    setFormErrors(prev => ({
                        ...prev,
                        email: ''
                    }));
                }
                break;
            }
            case 'password': {
                if (value) {
                    const strength = passwordStrength();
                    setFormErrors(prev => ({
                        ...prev,
                        password: strength.suggestions.length > 0 ? strength.suggestions[0] : ''
                    }));
                } else {
                    setFormErrors(prev => ({
                        ...prev,
                        password: ''
                    }));
                }
                break;
            }
            case 'confirmPassword': {
                if (value) {
                    setFormErrors(prev => ({
                        ...prev,
                        confirmPassword: value !== formData.password ? "Passwords don't match" : ''
                    }));
                } else {
                    setFormErrors(prev => ({
                        ...prev,
                        confirmPassword: ''
                    }));
                }
                break;
            }
            default:
                break;
        }
    };

    const passwordStrength = useCallback(() => {
        return getPasswordStrength(formData.password);
    }, [formData.password]);

    const handleSignUp = async (e) => {
        e.preventDefault();

        const nameValidation = validateName(formData.name);
        const emailValidation = validateEmail(formData.email);
        const passwordMatch = formData.password === formData.confirmPassword;

        setFormErrors({
            name: nameValidation.isValid ? '' : nameValidation.error,
            email: emailValidation.isValid ? '' : emailValidation.error,
            password: passwordStrength().suggestions.length > 0 ? passwordStrength().suggestions[0] : '',
            confirmPassword: passwordMatch ? '' : "Passwords don't match"
        });

        if (!nameValidation.isValid || 
            !emailValidation.isValid || 
            !passwordMatch || 
            passwordStrength().strength < 3) {
            return;
        }

        setIsLoading(true);
        try {
            const sanitizedData = {
                name: nameValidation.value,
                email: emailValidation.value,
                password: formData.password
            };
            
            await new Promise(resolve => setTimeout(resolve, 1000)); // Simulated API call
            console.log('Signup attempt with:', sanitizedData);
        } catch (err) {
            setFormErrors(prev => ({
                ...prev,
                general: `Signup failed: ${err.message}`
            }));
        } finally {
            setIsLoading(false);
        }
    };

    const togglePasswordVisibility = (field) => {
        setShowPassword(prev => ({
            ...prev,
            [field]: !prev[field]
        }));
    };

    // Add this check for required fields
    const isFormValid = () => {
        const hasAllRequiredFields = Object.values(formData).every(value => value.trim() !== '');
        const hasNoErrors = Object.values(formErrors).every(error => error === '');
        return hasAllRequiredFields && hasNoErrors;
    };

    const validateField = (name, value) => {
        switch (name) {
            case 'name': {
                const validation = validateName(value);
                setFormErrors(prev => ({
                    ...prev,
                    name: validation.isValid ? '' : validation.error
                }));
                break;
            }
            case 'email': {
                const validation = validateEmail(value);
                setFormErrors(prev => ({
                    ...prev,
                    email: validation.isValid ? '' : validation.error
                }));
                break;
            }
            case 'password': {
                const strength = passwordStrength();
                setFormErrors(prev => ({
                    ...prev,
                    password: strength.suggestions.length > 0 ? strength.suggestions[0] : ''
                }));
                // Also validate confirm password if it exists
                if (formData.confirmPassword) {
                    setFormErrors(prev => ({
                        ...prev,
                        confirmPassword: value !== formData.confirmPassword ? "Passwords don't match" : ''
                    }));
                }
                break;
            }
            case 'confirmPassword': {
                setFormErrors(prev => ({
                    ...prev,
                    confirmPassword: value !== formData.password ? "Passwords don't match" : ''
                }));
                break;
            }
            default:
                break;
        }
    };

    // Update handleBlur to use validateField
    const handleBlur = (e) => {
        const { name, value } = e.target;
        setTouchedFields(prev => ({
            ...prev,
            [name]: true
        }));
        validateField(name, value);
    };

    return (
        <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className={`max-w-md w-full mx-auto ${isDarkMode ? 'bg-gray-800' : 'bg-white'} bg-opacity-50 backdrop-filter backdrop-blur-xl rounded-2xl shadow-xl overflow-hidden p-4 sm:p-8`}
        >
            <h2 className="text-3xl font-bold mb-6 text-center bg-gradient-to-r from-green-400 to-emerald-500 text-transparent bg-clip-text">
                Create Account
            </h2>

            {formErrors.general && (
                <div className="mb-4 p-3 bg-red-500 bg-opacity-20 border border-red-500 rounded-lg text-red-500 text-sm">
                    {formErrors.general}
                </div>
            )}

            <form onSubmit={handleSignUp} className="space-y-4">
                <div className="space-y-1">
                    <Input
                        icon={User}
                        type="text"
                        name="name"
                        placeholder="Full Name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                        className={`${formErrors.name ? 'border-red-500' : isDarkMode ? 'border-gray-600' : 'border-gray-300'}`}
                        aria-invalid={!!formErrors.name}
                        onBlur={handleBlur}
                    />
                    {touchedFields.name && formErrors.name && (
                        <p className="text-xs text-red-500 mt-1">{formErrors.name}</p>
                    )}
                </div>

                <div className="space-y-1">
                    <Input
                        icon={Mail}
                        type="email"
                        name="email"
                        placeholder="Email Address"
                        value={formData.email}
                        onChange={handleChange}
                        required
                        className={`${formErrors.email ? 'border-red-500' : isDarkMode ? 'border-gray-600' : 'border-gray-300'}`}
                        aria-invalid={!!formErrors.email}
                        autoComplete="email"
                        inputMode="email"
                        onBlur={handleBlur}
                    />
                    {touchedFields.email && formErrors.email && (
                        <p className="text-xs text-red-500 mt-1">{formErrors.email}</p>
                    )}
                </div>

                <div className="space-y-1">
                    <div className="relative w-full">
                        <Input
                            icon={Lock}
                            type={showPassword.password ? "text" : "password"}
                            name="password"
                            placeholder="Password"
                            value={formData.password}
                            onChange={handleChange}
                            required
                            className={`w-full pr-10 ${formErrors.password ? 'border-red-500' : ''}`}
                            aria-invalid={!!formErrors.password}
                            onBlur={handleBlur}
                        />
                        <button
                            type="button"
                            onClick={() => togglePasswordVisibility('password')}
                            className={`absolute right-3 top-1/2 -translate-y-1/2 ${
                                isDarkMode ? 'text-gray-400 hover:text-gray-300' : 'text-gray-600 hover:text-gray-500'
                            } focus:outline-none z-10`}
                            aria-label={showPassword.password ? 'Hide password' : 'Show password'}
                        >
                            {!showPassword.password ? <EyeOff size={20} /> : <Eye size={20} />}
                        </button>
                    </div>
                    {formData.password && (
                        <div className="space-y-2">
                            <div className="w-full h-2 bg-gray-700 rounded-full overflow-hidden flex">
                                {[...Array(6)].map((_, index) => (
                                    <div
                                        key={index}
                                        className={`h-full flex-1 transition-all duration-300 ${
                                            index < passwordStrength().strength
                                                ? getStrengthColor(index)
                                                : isDarkMode ? 'bg-gray-700' : 'bg-gray-200'
                                        } ${index > 0 ? 'ml-0.5' : ''}`}
                                    />
                                ))}
                            </div>
                            <div className="flex items-center justify-between">
                                <p className={`text-xs ${passwordStrength().class}`}>
                                    Password strength: {passwordStrength().message}
                                </p>
                                <p className="text-xs text-gray-400">
                                    {passwordStrength().strength}/6
                                </p>
                            </div>
                            {passwordStrength().suggestions.length > 0 && (
                                <ul className={`text-xs ${isDarkMode ? 'text-gray-400' : 'text-gray-600'} list-disc list-inside`}>
                                    {passwordStrength().suggestions.map((suggestion, index) => (
                                        <li key={index}>{suggestion}</li>
                                    ))}
                                </ul>
                            )}
                        </div>
                    )}
                </div>

                <div className="relative w-full">
                    <Input
                        icon={Lock}
                        type={showPassword.confirmPassword ? "text" : "password"}
                        name="confirmPassword"
                        placeholder="Confirm Password"
                        value={formData.confirmPassword}
                        onChange={handleChange}
                        required
                        className={`w-full pr-10 ${formErrors.confirmPassword ? 'border-red-500' : ''}`}
                        aria-invalid={!!formErrors.confirmPassword}
                        onBlur={handleBlur}
                    />
                    <button
                        type="button"
                        onClick={() => togglePasswordVisibility('confirmPassword')}
                        className={`absolute right-3 top-1/2 -translate-y-1/2 ${
                            isDarkMode ? 'text-gray-400 hover:text-gray-300' : 'text-gray-600 hover:text-gray-500'
                        } focus:outline-none z-10`}
                        aria-label={showPassword.confirmPassword ? 'Hide password' : 'Show password'}
                    >
                        {!showPassword.confirmPassword ? <EyeOff size={20} /> : <Eye size={20} />}
                    </button>
                </div>
                {touchedFields.confirmPassword && formErrors.confirmPassword && (
                    <p className="text-xs text-red-500 mt-1">{formErrors.confirmPassword}</p>
                )}

                <button
                    type="submit"
                    className="w-full py-3 bg-gradient-to-r from-green-400 to-emerald-500 text-white rounded-lg 
                    font-semibold hover:opacity-90 transition duration-200 disabled:opacity-50"
                    disabled={!isFormValid() || isLoading}
                >
                    {isLoading ? (
                        <Loader className="animate-spin mx-auto" />
                    ) : (
                        'Sign Up'
                    )}
                </button>
            </form>

            <div className="mt-6 text-center">
                <p className={isDarkMode ? 'text-gray-400' : 'text-gray-600'}>
                    Already have an account?{" "}
                    <Link to="/login" className="text-green-400 hover:text-green-300">
                        Log In
                    </Link>
                </p>
            </div>
        </motion.div>
    );
};

export default SignUpPage;
