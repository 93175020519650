// ScrollContext.js
import React, { createContext, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollContext = createContext();

export const ScrollProvider = ({ children }) => {
  const location = useLocation();

  const disableScroll = () => {
    document.documentElement.style.overflow = 'hidden';
    document.body.style.overflow = 'hidden';
  };

  useEffect(() => {
    // Disable scrolling for all routes
    disableScroll();

    return () => {
      // Keep scroll disabled even on unmount
      disableScroll();
    };
  }, [location]);

  return (
    <ScrollContext.Provider value={{ disableScroll }}>
      {children}
    </ScrollContext.Provider>
  );
};

export const useScroll = () => useContext(ScrollContext);