import React, { useState, useRef } from 'react';
import { createPortal } from 'react-dom';
import { IconUserCircle, IconPhoto, IconFlag, IconArrowLeft } from '@tabler/icons-react';
import './PostBox.css';

function PostBox({ onPost }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [showActionTags, setShowActionTags] = useState(false);
  const [selectedTag, setSelectedTag] = useState(null);
  const popupRef = useRef(null);
  const textareaRef = useRef(null);
  const [postContent, setPostContent] = useState('');

  const handleCancel = () => {
    setIsExpanded(false);
  };

  const handlePost = () => {
    if (!postContent.trim()) {
      return; // Don't post empty content
    }
    
    console.log('PostBox - Posting content:', postContent);
    console.log('PostBox - Selected tag:', selectedTag);
    
    onPost(postContent, selectedTag);
    setPostContent(''); // Clear the input
    setSelectedTag(null); // Reset the tag
    setIsExpanded(false);
    setShowActionTags(false);
  };

  const handleActionTagClick = () => {
    setShowActionTags(true);
  };

  const handleTagSelect = (tag) => {
    setSelectedTag(tag);
    setShowActionTags(false);
  };

  const actionTags = [
    { id: 'solve', label: 'How can we solve this?' },
    { id: 'massacre', label: "They're being Massacared" },
    { id: 'explain', label: 'Someone explain this' },
    { id: 'action', label: 'We decided to do something about this' },
    { id: 'describe', label: 'Educate us' }
  ];

  const PostBoxButtons = () => (
    <div className="postBox__buttons">
      <button type="button" onClick={handleActionTagClick}>
        <IconFlag size={30} />
        <span className="button-text">
          {selectedTag ? selectedTag.label : 'Action Tags'}
        </span>
      </button>
      <button type="button">
        <IconPhoto size={30} />
        <span className="button-text">Image</span>
      </button>
    </div>
  );

  const handleTextareaChange = () => {
    const textarea = textareaRef.current;
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;
  };

  const isPostDisabled = () => {
    return !postContent.trim();
  };

  return (
    <div className="postBox">
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="postBox__input">
          <IconUserCircle className="postBox__avatar" />
          <input
            placeholder="Does your voice need to be heard?"
            onClick={() => {
              setIsExpanded(true);
            }}
          />
        </div>

        <PostBoxButtons />

        {isExpanded && createPortal(
          <div className="postBox__popup">
            <div className="postBox__popupContent" ref={popupRef}>
              <div className="postBox__input">
                <IconUserCircle className="postBox__avatar" />
                <div className="postBox__userInfo">
                  <span className="postBox__username">Raz Conteh</span>
                  <span className="postBox__profession">Beta Tester</span>
                </div>
              </div>
              <textarea
                ref={textareaRef}
                placeholder="Does your voice need to be heard?"
                autoFocus
                className="postBox__popupTextarea"
                onChange={(e) => {
                  setPostContent(e.target.value);
                  handleTextareaChange();
                }}
                value={postContent}
              />

              <div className="postBox__popupExtraButtons">
                <button type="button" onClick={handleActionTagClick}>
                  <IconFlag size={30} />
                  <span className="button-text">
                    {selectedTag ? selectedTag.label : 'Action Tags'}
                  </span>
                </button>
                <button type="button">
                  <IconPhoto size={30} />
                  <span className="button-text">Image</span>
                </button>
              </div>

              <div className="postBox__popupButtons">
                <button
                  type="button"
                  onClick={handleCancel}
                  className="postBox__popupCancelButton"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={handlePost}
                  className={`postBox__popupPostButton ${isPostDisabled() ? 'disabled' : ''}`}
                  disabled={isPostDisabled()}
                >
                  Post
                </button>
              </div>
            </div>
          </div>,
          document.body
        )}

        {showActionTags && createPortal(
          <div className="actionTags__popup">
            <div className="actionTags__popupContent" ref={popupRef}>
              <div className="actionTags__header">
                <button type="button" onClick={() => setShowActionTags(false)} className="actionTags__backButton">
                  <IconArrowLeft size={24} />
                </button>
                <h2 className="action-tag-header">Pick an Action Tag</h2>
              </div>
              <ul className="action-tag-options">
                {actionTags.map((tag) => (
                  <li key={tag.id} className="action-tag-option">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <input
                        type="radio"
                        id={tag.id}
                        name="actionTag"
                        value={tag.id}
                        checked={selectedTag && selectedTag.id === tag.id}
                        onChange={() => handleTagSelect(tag)}
                      />
                      <label htmlFor={tag.id} className="action-tag-label">{tag.label}</label>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>,
          document.body
        )}
      </form>
    </div>
  );
}

export default PostBox;
