// Feed.js
import React, { useState } from 'react';
import './Feed.css';
import PostBox from './PostBox';
import Post from './Post';

const Feed = ({ onPostCountChange }) => {
    const [posts, setPosts] = useState([]);

    const handleNewPost = (content, actionTag) => {
        console.log('Feed - Received new post:', content);
        console.log('Feed - Received action tag:', actionTag);
        
        if (!content.trim()) return;
        
        const newPost = {
            id: Date.now().toString(),
            username: "Raz Conteh",
            profession: "Beta Tester",
            timestamp: new Date().toISOString(),
            content: content,
            actionTag: actionTag ? {
                id: actionTag.id,
                label: actionTag.label
            } : null
        };
        
        console.log('Feed - Creating new post:', newPost);
        
        setPosts(prevPosts => {
            const newPosts = [newPost, ...prevPosts];
            console.log('Feed - Updated posts:', newPosts);
            if (onPostCountChange) {
                onPostCountChange(newPosts.length, newPosts);
            }
            return newPosts;
        });
    };

    const handleSave = (postId) => {
        setPosts(prevPosts => prevPosts.map(post => {
            if (post.id === postId) {
                return {
                    ...post,
                    isSaved: !post.isSaved
                };
            }
            return post;
        }));
    };

    return (
        <div className="feed-container">
            <div className="feed-header">
                <PostBox onPost={handleNewPost} />
            </div>
            <div className="feed-content">
                <div className="feed">
                    {posts.map((post) => (
                        <Post
                            key={post.id}
                            postId={post.id}
                            username={post.username}
                            profession={post.profession}
                            timestamp={post.timestamp}
                            content={post.content}
                            actionTag={post.actionTag}
                            onUnsave={() => handleSave(post.id)}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

Feed.defaultProps = {
    onPostCountChange: () => {}
};

export default Feed;