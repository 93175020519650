import React, { useState } from 'react';
import { IconBell, IconMessages, IconHome } from '@tabler/icons-react';
import { NavLink } from 'react-router-dom';
import SearchComponent from './SearchComponent';
import ChatPanel from './ChatPanel';
import './Header.css';

function Header() {
  const [isChatPanelOpen, setIsChatPanelOpen] = useState(false);

  const toggleChatPanel = () => {
    setIsChatPanelOpen(!isChatPanelOpen);
  };

  return (
    <div className="header">
      <div className="header__left">
        <h2>WEHEARTHEM</h2>
        <NavLink 
          to="/home" 
          className={({ isActive }) => isActive ? 'header__icon active' : 'header__icon'}
        >
          <IconHome />
        </NavLink>
      </div>
      
      <div className="header__center">
        <SearchComponent />
      </div>

      <div className="header__right">
        <NavLink 
          to="/notifications" 
          className={({ isActive }) => isActive ? 'header__icon active' : 'header__icon'}
        >
          <IconBell />
        </NavLink>

        <div 
          className={isChatPanelOpen ? 'header__icon active' : 'header__icon'}
          onClick={toggleChatPanel}
        >
          <IconMessages />
        </div>
      </div>

      {isChatPanelOpen && (
        <ChatPanel onClose={toggleChatPanel} />
      )}
    </div>
  );
}

export default Header;
