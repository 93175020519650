// SidebarOption.js
import React, { useState } from 'react';
import './SidebarOption.css';

function SidebarOption({ active, text, Icon, iconColor, comingSoon }) {
  const [isHovered, setIsHovered] = useState(false);

  const getOptionClass = () => {
    switch (text) {
      case "Contributions":
        return "contributions";
      case "Followed Projects":
        return "followed-projects";
      case "Your Projects":
        return "your-projects";
      case "Files":
        return "files";
      case "Logout":
        return "logout";
      default:
        return "";
    }
  };

  return (
    <div 
      className={`sidebarOption ${getOptionClass()} ${active ? "sidebarOption--active" : ""}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Icon 
        className="custom-icon-class"
        style={{ 
          color: iconColor,
          stroke: iconColor,
          strokeWidth: 1.5
        }}
      />
      <h2>{comingSoon && isHovered ? "COMING SOON" : text}</h2>
    </div>
  );
}

export default SidebarOption;