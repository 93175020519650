export const SAVED_POSTS_KEY = 'savedPosts';

export const getSavedPosts = () => {
    try {
        const savedPosts = localStorage.getItem(SAVED_POSTS_KEY);
        const posts = savedPosts ? JSON.parse(savedPosts) : [];
        // Filter out invalid posts
        const validPosts = posts.filter(post => 
            post && 
            post.id && 
            post.username && 
            post.profession && 
            post.content
        );
        // Update localStorage with only valid posts
        if (validPosts.length !== posts.length) {
            localStorage.setItem(SAVED_POSTS_KEY, JSON.stringify(validPosts));
        }
        return validPosts;
    } catch (error) {
        console.error('Error reading from localStorage:', error);
        return [];
    }
};

export const savePost = (post) => {
    const savedPosts = getSavedPosts();
    const updatedPosts = [...savedPosts, post];
    localStorage.setItem(SAVED_POSTS_KEY, JSON.stringify(updatedPosts));
    // Dispatch event for post saved
    window.dispatchEvent(new Event('postsUpdated'));
};

export const unsavePost = (postId) => {
    const savedPosts = getSavedPosts();
    const updatedPosts = savedPosts.filter(post => post.id !== postId);
    localStorage.setItem(SAVED_POSTS_KEY, JSON.stringify(updatedPosts));
    // Dispatch event for post unsaved
    window.dispatchEvent(new Event('postsUpdated'));
};

export const isPostSaved = (postId) => {
    const savedPosts = getSavedPosts();
    return savedPosts.some(post => post.id === postId);
}; 