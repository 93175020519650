import React, { useState, useEffect, useRef } from 'react';
import './Post.css';
import { 
    IconUserCircle, 
    IconHeart, 
    IconShare3,
    IconBookmark, 
    IconBookmarkFilled,
    IconMessageCircle,
    IconFlag,
    IconBrandTwitter,
    IconBrandFacebook,
    IconBrandLinkedin,
    IconMail,
    IconCopy
} from '@tabler/icons-react';
import CommentBox from './CommentBox';
import { savePost, unsavePost, isPostSaved } from './utils/localStorage';

export default function Post({ 
    username, 
    profession, 
    timestamp, 
    avatar, 
    content, 
    actionTag,
    postId,
    onUnsave 
}) {
    console.log('Post rendering with props:', {
        username,
        profession,
        timestamp,
        content,
        actionTag,
        postId
    });

    const [showCommentBox, setShowCommentBox] = useState(false);
    const [postLikes, setPostLikes] = useState(0);
    const [isPostLiked, setIsPostLiked] = useState(false);
    const [commentCount, setCommentCount] = useState(0);
    const [postedComments, setPostedComments] = useState([]);
    const [commentReplies, setCommentReplies] = useState({});
    const [showShareModal, setShowShareModal] = useState(false);
    const [copyFeedback, setCopyFeedback] = useState(false);
    const [shareError, setShareError] = useState(null);
    const [isSaved, setIsSaved] = useState(() => isPostSaved(postId));
    const shareButtonRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (showShareModal && !event.target.closest('.post-share-options')) {
                setShowShareModal(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [showShareModal]);

    if (!username || !profession || !content || !postId) {
        return null; // Don't render incomplete posts
    }

    const handleMessageClick = () => {
        setShowCommentBox(!showCommentBox);
    };

    const handlePostLike = () => {
        setIsPostLiked(!isPostLiked);
        setPostLikes(prevLikes => isPostLiked ? prevLikes - 1 : prevLikes + 1);
    };

    const handleShare = async (type) => {
        setShareError(null);
        const shareText = `${username}'s post: ${content}`;
        const shareUrl = `${window.location.origin}/post/${postId}`;

        try {
            switch(type) {
                case 'copy':
                    try {
                        await navigator.clipboard.writeText(`${shareText}\n\n${shareUrl}`);
                        setCopyFeedback(true);
                        setTimeout(() => {
                            setCopyFeedback(false);
                            setShowShareModal(false);
                        }, 2000);
                    } catch (err) {
                        throw new Error('Failed to copy to clipboard');
                    }
                    break;

                case 'twitter':
                    const twitterUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(shareText)}&url=${encodeURIComponent(shareUrl)}`;
                    window.open(twitterUrl, '_blank', 'width=550,height=420');
                    setShowShareModal(false);
                    break;

                case 'facebook':
                    const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}`;
                    window.open(facebookUrl, '_blank', 'width=550,height=420');
                    setShowShareModal(false);
                    break;

                case 'linkedin':
                    const linkedinUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(shareUrl)}`;
                    window.open(linkedinUrl, '_blank', 'width=550,height=420');
                    setShowShareModal(false);
                    break;

                case 'email':
                    const subject = encodeURIComponent(`Check out this post from ${username}`);
                    const body = encodeURIComponent(`${shareText}\n\n${shareUrl}`);
                    window.location.href = `mailto:?subject=${subject}&body=${body}`;
                    setShowShareModal(false);
                    break;

                default:
                    throw new Error('Invalid share type');
            }
        } catch (error) {
            console.error('Share error:', error);
            setShareError(error.message || 'Failed to share. Please try again.');
        }
    };

    const formatTimestamp = (timestamp) => {
        if (!timestamp) return '';
        
        const date = new Date(timestamp);
        const now = new Date();
        const diffInSeconds = Math.floor((now - date) / 1000);
        
        if (diffInSeconds < 60) {
            return 'Just now';
        } else if (diffInSeconds < 3600) {
            const minutes = Math.floor(diffInSeconds / 60);
            return `${minutes}m`;
        } else if (diffInSeconds < 86400) {
            const hours = Math.floor(diffInSeconds / 3600);
            return `${hours}h`;
        } else if (diffInSeconds < 604800) {
            const days = Math.floor(diffInSeconds / 86400);
            return `${days}d`;
        } else {
            return date.toLocaleDateString('en-US', {
                month: 'short',
                day: 'numeric'
            });
        }
    };

    const handleSave = () => {
        const post = {
            id: postId,
            username,
            profession,
            timestamp,
            avatar,
            content,
            actionTag
        };

        if (isSaved) {
            unsavePost(postId);
            onUnsave && onUnsave(postId);
        } else {
            savePost(post);
        }
        
        setIsSaved(!isSaved);
    };

    return (
        <>
            <div className='post'>
                <div className='post__avatar'>
                    <IconUserCircle />
                </div>
                <div className='post__body'>
                    <div className='post__header'>
                        <div className='post__headerText'>
                            <h3>{username}</h3>
                        </div>
                        <div className='post__headerDescription'>
                            <h4>{profession} • {formatTimestamp(timestamp)}</h4>
                        </div>
                    </div>
                    {actionTag && (
                        <div className='post__actionTag'>
                            <IconFlag size={20} className="post__actionIcon" />
                            <span>{actionTag.label}</span>
                        </div>
                    )}
                    <div className='post__content'>
                        <p>{content}</p>
                    </div>
                    <div className='post__footer'>
                        <div className='postButtons'>
                            <button 
                                className={`comment-like-btn ${isPostLiked ? 'liked' : ''}`}
                                onClick={handlePostLike}
                            >
                                <IconHeart size={20} />
                                <span className="button-text">{postLikes}</span>
                            </button>
                            <button 
                                className={`comment-reply-btn ${showCommentBox ? 'active' : ''}`}
                                onClick={handleMessageClick}
                            >
                                <IconMessageCircle size={20} />
                                <span className="button-text">{commentCount}</span>
                            </button>
                            <button 
                                ref={shareButtonRef}
                                className="comment-share-btn"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setShowShareModal(!showShareModal);
                                    setShareError(null);
                                }}
                            >
                                <IconShare3 size={20} />
                                <span className="button-text">Share</span>
                            </button>
                            <button 
                                className={`comment-bookmark-btn ${isSaved ? 'saved' : ''}`}
                                onClick={handleSave}
                            >
                                {isSaved ? <IconBookmarkFilled size={20} /> : <IconBookmark size={20} />}
                                <span className="button-text">Save</span>
                            </button>
                        </div>

                        {showCommentBox && (
                            <div className="comment-section show">
                                <CommentBox
                                    onCommentCountChange={setCommentCount}
                                    onReplyCountChange={(prev) => {}}
                                    postedComments={postedComments}
                                    setPostedComments={setPostedComments}
                                    commentReplies={commentReplies}
                                    setCommentReplies={setCommentReplies}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>

            {showShareModal && (
                <div 
                    className="post-share-options"
                    style={{
                        position: 'fixed',
                        left: `${shareButtonRef.current?.getBoundingClientRect().left}px`,
                        top: `${shareButtonRef.current?.getBoundingClientRect().bottom + 5}px`
                    }}
                >
                    {shareError && (
                        <div className="share-error-message">
                            {shareError}
                            <button onClick={() => setShareError(null)}>Dismiss</button>
                        </div>
                    )}
                    
                    <button onClick={() => handleShare('copy')}>
                        <IconCopy size={14} />
                        {copyFeedback ? 'Copied!' : 'Copy link'}
                    </button>
                    
                    <button onClick={() => handleShare('twitter')}>
                        <IconBrandTwitter size={14} />
                        Share to Twitter
                    </button>
                    
                    <button onClick={() => handleShare('facebook')}>
                        <IconBrandFacebook size={14} />
                        Share to Facebook
                    </button>
                    
                    <button onClick={() => handleShare('linkedin')}>
                        <IconBrandLinkedin size={14} />
                        Share to LinkedIn
                    </button>
                    
                    <button onClick={() => handleShare('email')}>
                        <IconMail size={14} />
                        Share via Email
                    </button>
                </div>
            )}
        </>
    );
}
