import React, { useState, useEffect } from 'react';
import { IconHeart, IconMessage, IconUserPlus, IconMessageCircle2, IconThumbUp } from '@tabler/icons-react';
import './Notifications.css';

const Notifications = () => {
    const [notifications, setNotifications] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Move dummyNotifications inside useEffect
        const dummyNotifications = [
            {
                id: 1,
                type: 'follow',
                actor: 'Sarah Chen',
                timestamp: '2 minutes ago',
                read: false,
                actorId: '123'
            },
            {
                id: 2,
                type: 'like',
                actor: 'John Doe',
                postTitle: 'Climate Change Effects in Urban Areas',
                timestamp: '5 hours ago',
                read: true,
                postId: '456',
                actorId: '124'
            },
            {
                id: 3,
                type: 'comment',
                actor: 'Maria Garcia',
                postTitle: 'Education Inequality in Rural Areas',
                comment: 'This is a very important issue that needs...',
                timestamp: '1 day ago',
                read: false,
                postId: '789',
                actorId: '125'
            },
            {
                id: 4,
                type: 'reply',
                actor: 'Alex Kim',
                postTitle: 'Mental Health Awareness',
                reply: 'I completely agree with your point about...',
                timestamp: '2 days ago',
                read: true,
                postId: '101',
                actorId: '126'
            },
            {
                id: 5,
                type: 'message',
                actor: 'Emma Wilson',
                message: 'Hey, I\'d like to discuss your recent post about...',
                timestamp: '3 days ago',
                read: false,
                actorId: '127'
            }
        ];

        // Simulate API call
        setTimeout(() => {
            setNotifications(dummyNotifications);
            setLoading(false);
        }, 1000);
    }, []); // Empty dependency array since we don't have external dependencies

    const getNotificationIcon = (type) => {
        switch (type) {
            case 'follow':
                return <IconUserPlus className="notification-icon follow" />;
            case 'like':
                return <IconHeart className="notification-icon like" />;
            case 'comment':
                return <IconMessageCircle2 className="notification-icon comment" />;
            case 'reply':
                return <IconThumbUp className="notification-icon reply" />;
            case 'message':
                return <IconMessage className="notification-icon message" />;
            default:
                return null;
        }
    };

    const getNotificationText = (notification) => {
        switch (notification.type) {
            case 'follow':
                return `started following you`;
            case 'like':
                return `liked your post "${notification.postTitle}"`;
            case 'comment':
                return `commented on your post "${notification.postTitle}"`;
            case 'reply':
                return `replied to your comment on "${notification.postTitle}"`;
            case 'message':
                return `sent you a message`;
            default:
                return '';
        }
    };

    const handleNotificationClick = (notification) => {
        // Handle navigation based on notification type
        switch (notification.type) {
            case 'follow':
                // Navigate to user profile
                console.log(`Navigate to profile/${notification.actorId}`);
                break;
            case 'like':
            case 'comment':
            case 'reply':
                // Navigate to post
                console.log(`Navigate to post/${notification.postId}`);
                break;
            case 'message':
                // Navigate to messages
                console.log(`Navigate to messages/${notification.actorId}`);
                break;
            default:
                break;
        }
    };

    return (
        <div className="notifications-container">
            <h2 className="notifications-title">Notifications</h2>
            
            {loading ? (
                <div className="loading">Loading notifications...</div>
            ) : (
                <div className="notifications-list">
                    {notifications.map((notification) => (
                        <div 
                            key={notification.id}
                            className={`notification-item ${!notification.read ? 'unread' : ''}`}
                            onClick={() => handleNotificationClick(notification)}
                        >
                            {getNotificationIcon(notification.type)}
                            <div className="notification-content">
                                <div className="notification-header">
                                    <span className="actor-name">{notification.actor}</span>
                                    <span className="timestamp">{notification.timestamp}</span>
                                </div>
                                <p className="notification-text">
                                    {getNotificationText(notification)}
                                </p>
                                {(notification.type === 'comment' || notification.type === 'reply') && (
                                    <p className="notification-preview">
                                        {notification.type === 'comment' ? notification.comment : notification.reply}
                                    </p>
                                )}
                                {notification.type === 'message' && (
                                    <p className="notification-preview">
                                        {notification.message}
                                    </p>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default Notifications; 