import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const SearchComponent = () => {
    const [searchQuery, setSearchQuery] = useState("");
    const navigate = useNavigate();
    const location = useLocation();
    const searchRef = useRef(null);
    const inputRef = useRef(null);

    const handleSearchChange = (e) => {
        const query = e.target.value;
        setSearchQuery(query);
        if (query.length > 0) {
            navigate(`/search?query=${query}`, { replace: true });
        } else {
            if (location.pathname === '/search') {
                navigate('/home');
            }
        }
    };

    useEffect(() => {
        if (location.pathname !== '/search') {
            setSearchQuery('');
        }
    }, [location.pathname]);

    return (
        <div ref={searchRef} className="relative w-full max-w-2xl">
            <div className="relative">
                <input
                    ref={inputRef}
                    type="text"
                    placeholder="Search..."
                    className="header__searchInput"
                    value={searchQuery}
                    onChange={handleSearchChange}
                />
            </div>
        </div>
    );
};

export default SearchComponent;