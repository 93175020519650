import { useState, useEffect } from 'react';
import { motion, AnimatePresence } from "framer-motion";
import { Mail, Lock, Loader, Eye, EyeOff } from "lucide-react";
import { Link } from 'react-router-dom';
import Input from "./input.jsx";
import { validateEmail } from './utils/validation.js';

const formVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.5,
      when: "beforeChildren",
      staggerChildren: 0.1
    }
  }
};

const inputVariants = {
  hidden: { opacity: 0, y: 10 },
  visible: { 
    opacity: 1, 
    y: 0,
    transition: {
      duration: 0.2
    }
  }
};

const LoginPage = ({ isDarkMode }) => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [attemptCount, setAttemptCount] = useState(0);
  const [showPassword, setShowPassword] = useState(false);
  const [touchedFields, setTouchedFields] = useState({
    email: false,
    password: false
  });
  const MAX_ATTEMPTS = 5;
  const LOCKOUT_TIME = 300000; // 5 minutes

  useEffect(() => {
    const savedEmail = localStorage.getItem('rememberedEmail');
    if (savedEmail) {
      setFormData(prev => ({ ...prev, email: savedEmail }));
      setRememberMe(true);
    }
  }, []);

  const validateForm = () => {
    const newErrors = {};
    if (!formData.email.trim()) {
      newErrors.email = 'Email is required';
    } else {
      const emailValidation = validateEmail(formData.email);
      if (!emailValidation.isValid) {
        newErrors.email = emailValidation.error;
      }
    }
    if (!formData.password.trim()) {
      newErrors.password = 'Password is required';
    } else if (formData.password.length < 6) {
      newErrors.password = 'Password must be at least 6 characters';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    validateForm();
  };

  const handleRememberMe = () => {
    setRememberMe(!rememberMe);
    if (rememberMe) {
      localStorage.removeItem('rememberedEmail');
    } else {
      localStorage.setItem('rememberedEmail', formData.email);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (attemptCount >= MAX_ATTEMPTS) {
      setErrors({ general: 'Too many attempts. Please try again in 5 minutes.' });
      setTimeout(() => setAttemptCount(0), LOCKOUT_TIME);
      return;
    }
    setIsLoading(true);
    try {
      await new Promise(resolve => setTimeout(resolve, 1000)); // Simulated API call
      console.log('Login attempt with:', formData);
      if (rememberMe) {
        localStorage.setItem('rememberedEmail', formData.email);
      }
    } catch (err) {
      const errorMessage = err.response?.data?.message || 'An error occurred during login';
      setErrors({ general: errorMessage });
      setAttemptCount(prev => prev + 1);
    } finally {
      setIsLoading(false);
    }
  };

  const handleBlur = (e) => {
    const { name } = e.target;
    setTouchedFields(prev => ({
      ...prev,
      [name]: true
    }));
    validateForm();
  };

  const togglePasswordVisibility = () => {
    setShowPassword(prev => !prev);
  };

  const isFormValid = () => {
    return Object.keys(errors).length === 0;
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className={`max-w-md w-full mx-auto ${
        isDarkMode 
          ? 'bg-gray-950/40' 
          : 'bg-white/30'
      } backdrop-filter backdrop-blur-xl rounded-2xl shadow-xl overflow-hidden p-4 sm:p-8`}
    >
      <motion.h2 
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        className="text-3xl font-bold mb-6 text-center bg-gradient-to-r from-green-400 to-emerald-500 text-transparent bg-clip-text"
      >
        Welcome Back
      </motion.h2>

      <AnimatePresence>
        {errors.general && (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0 }}
            className="mb-4 p-3 bg-red-500 bg-opacity-20 border border-red-500 rounded-lg text-red-500 text-sm"
          >
            {errors.general}
          </motion.div>
        )}
      </AnimatePresence>

      <motion.form 
        variants={formVariants}
        initial="hidden"
        animate="visible"
        className="space-y-4"
        onSubmit={handleSubmit}
      >
        {/* Email Input */}
        <motion.div variants={inputVariants}>
          <Input
            icon={Mail}
            type="email"
            name="email"
            placeholder="Email Address"
            value={formData.email}
            onChange={handleChange}
            required
            className={errors.email ? 'border-red-500' : ''}
            aria-invalid={!!errors.email}
            onBlur={handleBlur}
            autoComplete="email"
            inputMode="email"
          />
          {touchedFields.email && errors.email && (
            <p className="text-xs text-red-500 mt-1">{errors.email}</p>
          )}
        </motion.div>

        {/* Password Input */}
        <motion.div variants={inputVariants}>
          <div className="relative w-full">
            <Input
              icon={Lock}
              type={showPassword ? "text" : "password"}
              name="password"
              placeholder="Password"
              value={formData.password}
              onChange={handleChange}
              required
              className={`w-full pr-10 ${errors.password ? 'border-red-500' : ''}`}
              aria-invalid={!!errors.password}
              onBlur={handleBlur}
            />
            <button
              type="button"
              onClick={togglePasswordVisibility}
              className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-400 hover:text-gray-300 focus:outline-none z-10"
            >
              {!showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
            </button>
          </div>
          {touchedFields.password && errors.password && (
            <p className="text-xs text-red-500 mt-1">{errors.password}</p>
          )}
        </motion.div>

        {/* Remember Me and Forgot Password */}
        <motion.div variants={inputVariants}>
          <div className="flex items-center justify-between text-sm">
            <label className="flex items-center text-gray-400">
              <input 
                type="checkbox"
                className="mr-2 rounded border-gray-600 bg-gray-700 text-green-500 focus:ring-green-500"
                checked={rememberMe}
                onChange={handleRememberMe}
              />
              Remember me
            </label>
            <Link to="/forgot-password" className="text-green-400 hover:text-green-300">
              Forgot password?
            </Link>
          </div>
        </motion.div>

        {/* Submit Button */}
        <motion.button
          variants={inputVariants}
          type="submit"
          className={`w-full py-3 bg-gradient-to-r from-green-400 to-emerald-500 text-white rounded-lg 
              font-semibold transition duration-200 hover:opacity-90 ${!isFormValid() || isLoading ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}`}
          disabled={!isFormValid() || isLoading}
        >
          {isLoading ? <Loader className="animate-spin mx-auto" /> : 'Log In'}
        </motion.button>
      </motion.form>

      {/* Divider */}
      <motion.div 
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.3 }}
        className="relative my-6 flex items-center justify-center space-x-4"
      >
        {/* Left border */}
        <div className={`w-full border-t ${isDarkMode ? 'border-gray-600' : 'border-gray-200'}`}></div>
        
        {/* OR text */}
        <span className={`flex-shrink-0 ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
          OR
        </span>
        
        {/* Right border */}
        <div className={`w-full border-t ${isDarkMode ? 'border-gray-600' : 'border-gray-200'}`}></div>
      </motion.div>

      {/* Google Sign In */}
      <motion.button
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.4 }}
        disabled={isLoading}
        className="w-full py-3 px-4 border border-gray-600 rounded-lg flex items-center justify-center space-x-2 text-gray-300 hover:bg-gray-700/50 transition duration-200 disabled:opacity-50"
      >
        <svg className="w-5 h-5" viewBox="0 0 24 24">
          <path
            d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
            fill="#4285F4"
          />
          <path
            d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
            fill="#34A853"
          />
          <path
            d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
            fill="#FBBC05"
          />
          <path
            d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
            fill="#EA4335"
          />
        </svg>
        <span>Sign in with Google</span>
      </motion.button>

      {/* Sign Up Link */}
      <motion.div 
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.5 }}
        className="mt-6 text-center"
      >
        <p className="text-gray-400">
          Don&apos;t have an account?{" "}
          <Link to="/signup" className="text-green-400 hover:text-green-300">
            Sign up
          </Link>
        </p>
      </motion.div>
    </motion.div>
  );
};

export default LoginPage;
