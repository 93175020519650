import React, { useState, } from 'react';
import './UserProfile.css';
import Post from './Post.js'; // Import the Post component
import { IconPencil, IconUserCircle, IconReplace } from '@tabler/icons-react';
import { Card, CardHeader, CardContent, Button, Tooltip } from '@mui/material'; // Ensure you have @mui/material installed

const UserProfile = () => {
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
    const [showPosts, setShowPosts] = useState(false);
    const [isPostsActive, setIsPostsActive] = useState(false);
    const [editField, setEditField] = useState('');
    const [name, setName] = useState("Raz Conteh");
    const [jobTitle, setJobTitle] = useState("Cancer Researcher");
    const [location, setLocation] = useState("Melbourne, Victoria, Australia");
    const [vision, setVision] = useState("But I must explain to you how all this mistaken idea of denouncing pleasure...");
    const [newValue, setNewValue] = useState('');
    // eslint-disable-next-line no-unused-vars
    const [postCount, setPostCount] = useState(0);
    // eslint-disable-next-line no-unused-vars
    const [userPosts, setUserPosts] = useState([]);

    const handleEditProfileClick = () => {
        setIsPopupOpen(true);
    };

    const handleClosePopup = () => {
        setIsPopupOpen(false);
    };

    const handleOpenEditPopup = (field) => {
        setEditField(field);
        setNewValue(field === 'name' ? name : field === 'jobTitle' ? jobTitle : field === 'location' ? location : vision);
        setIsEditPopupOpen(true);
    };

    const handleCloseEditPopup = () => {
        setIsEditPopupOpen(false);
    };

    const handleSaveEdit = () => {
        if (editField === 'name') {
            setName(newValue);
        } else if (editField === 'jobTitle') {
            setJobTitle(newValue);
        } else if (editField === 'location') {
            setLocation(newValue);
        } else if (editField === 'vision') {
            setVision(newValue);
        }
        handleCloseEditPopup();
    };

    const togglePosts = () => {
        setShowPosts(!showPosts); // Toggle the visibility of posts
        setIsPostsActive(!isPostsActive); // Toggle the active state
    };

    return (
        <div className="user-profile">
            <div className="cover-photo"></div>
            <div className="profile-section">
                <div className="avatar">
                    <IconUserCircle className='avatar' />
                </div>
                <div className="user-info">
                    <h2 className="user-name">{name}</h2>
                    <p className="job-title">{jobTitle}</p>
                    <p className="location">{location}</p>
                </div>
                <button className="edit-profile" onClick={handleEditProfileClick}>
                    <IconPencil /> Edit Profile
                </button>
            </div>
            
            <div className="stats-section">
                <div className={`stat-box ${isPostsActive ? 'active' : ''}`} onClick={togglePosts} style={{ cursor: 'pointer' }}>
                    <span className="stat-label">Posts</span>
                    <span className="stat-number">{postCount}</span>
                </div>
                <div className="stat-box">
                    <span className="stat-label">Followers</span>
                    <span className="stat-number">48</span>
                </div>
                <div className="stat-box">
                    <span className="stat-label">Skills</span>
                    <span className="stat-number">3</span>
                </div>
                <Tooltip title="This feature is coming soon" arrow placement="top">
                    <div className="stat-box projects-stat">
                        <span className="stat-label">Projects</span>
                        <span className="stat-number">0</span>
                    </div>
                </Tooltip>
            </div>

            <div className="vision-section">
                <h2 className="vision-title">My Vision</h2>
                <p className="vision-text">{vision}</p>
            </div>

            {/* Modified Posts section */}
            {showPosts && (
                <div className="user-posts-section">
                    <h2>My Posts</h2>
                    {userPosts.length > 0 ? (
                        userPosts.map((post) => (
                            <Post
                                key={post.id}
                                {...post}
                            />
                        ))
                    ) : (
                        <p className="no-posts-message">No posts yet</p>
                    )}
                </div>
            )}

            {isPopupOpen && (
                <div className="popup-overlay">
                    <div className="popup">
                        <Card className="popup-card">
                            <CardHeader
                                title="Edit Profile"
                                action={<Button onClick={handleClosePopup}>Close</Button>}
                            />
                            <CardContent>
                                <div className="cover-photo-pop">
                                    <div className="avatar-popup">
                                        <IconUserCircle />
                                    </div>
                                    <IconReplace className="photo-icon" />
                                </div>
                                <div className="user-details">
                                    <div className="input-container">
                                        <span>{name}</span>
                                        <IconPencil className="edit-icon" onClick={() => handleOpenEditPopup('name')} />
                                    </div>
                                    <div className="input-container">
                                        <span>{jobTitle}</span>
                                        <IconPencil className="edit-icon" onClick={() => handleOpenEditPopup('jobTitle')} />
                                    </div>
                                    <div className="input-container">
                                        <span>{location}</span>
                                        <IconPencil className="edit-icon" onClick={() => handleOpenEditPopup('location')} />
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                        <Card className="popup-card">
                            <CardHeader 
                                title={
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <span>My Vision</span>
                                        <IconPencil className="edit-icon" onClick={() => handleOpenEditPopup('vision')} style={{ marginLeft: '10px' }} />
                                    </div>
                                } 
                                style={{ textAlign: 'left' }} 
                            />
                            <CardContent>
                                <div className="vision-edit-section">
                                    <div className="vision-text">
                                        {vision}
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                    </div>
                </div>
            )}

            {isEditPopupOpen && (
                <div className="popup-overlay">
                    <div className="popup">
                        <Card className="popup-card">
                            <CardHeader
                                title={`Edit ${editField.charAt(0).toUpperCase() + editField.slice(1)}`}
                                action={<Button onClick={handleCloseEditPopup}>Close</Button>}
                            />
                            <CardContent>
                                <input
                                    type="text"
                                    value={newValue}
                                    onChange={(e) => setNewValue(e.target.value)}
                                    className="vision-input"
                                />
                                <div className="button-container">
                                    <Button onClick={handleSaveEdit}>Save</Button>
                                    <Button onClick={handleCloseEditPopup}>Cancel</Button>
                                </div>
                            </CardContent>
                        </Card>
                    </div>
                </div>
            )}
        </div>
    );
};

export default UserProfile;
