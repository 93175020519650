import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { IconUserCircle } from '@tabler/icons-react';
import './SearchResults.css';

const SearchResults = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const searchTerm = new URLSearchParams(location.search).get('query') || '';
    const [activeTab, setActiveTab] = useState('posts');
    const [loading, setLoading] = useState(true);

    // Keep only the people data as it's the main functioning feature
    const peopleData = [
        { id: 1, name: "Omid Ahmed", profession: "Data Scientist", location: "San Francisco, CA" },
        { id: 2, name: "Raz Conteh", profession: "Web Developer", location: "New York, NY" },
        { id: 3, name: "Alice Johnson", profession: "Environmental Scientist", location: "Los Angeles, CA" },
        { id: 4, name: "Bob Wilson", profession: "Social Worker", location: "Chicago, IL" }
    ];

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 1000);
        return () => clearTimeout(timer);
    }, []);

    const handlePersonItemClick = (person) => {
        navigate(`/profile2/${person.id}`, { state: { person } });
    };

    return (
        <div className="search-results-container">
            {loading && <div className="loader">Loading...</div>}
            <div className="search-tabs">
                <button
                    onClick={() => setActiveTab('posts')}
                    className={`search-tab ${activeTab === 'posts' ? 'active' : ''}`}
                    data-tab="posts"
                >
                    Posts
                    <span className="coming-soon-indicator">Coming Soon</span>
                </button>
                <button
                    onClick={() => setActiveTab('people')}
                    className={`search-tab ${activeTab === 'people' ? 'active' : ''}`}
                    data-tab="people"
                >
                    People
                    <span className="beta-indicator">Beta</span>
                </button>
                <button
                    onClick={() => setActiveTab('societal-issues')}
                    className={`search-tab ${activeTab === 'societal-issues' ? 'active' : ''}`}
                    data-tab="societal-issues"
                >
                    Societal Issues
                    <span className="coming-soon-indicator">Coming Soon</span>
                </button>
            </div>

            <div className="results">
                {activeTab === 'posts' && (
                    <div className="content-section">
                        <span className="underdevelopment-indicator">
                            This feature is under development and will be available soon!
                        </span>
                    </div>
                )}

                {activeTab === 'people' && (
                    <div className="people-container">
                        {peopleData.filter(person => person.name.toLowerCase().includes(searchTerm.toLowerCase())).map(person => (
                            <div 
                                key={person.id} 
                                className="result-item person-item"
                                onClick={() => handlePersonItemClick(person)}
                            >
                                <IconUserCircle className="icon" />
                                <div className="text-container">
                                    <h4>{person.name}</h4>
                                    <p className="profession">{person.profession}</p>
                                    <p className="location">{person.location}</p>
                                </div>
                                <button className="follow-button">Follow</button>
                            </div>
                        ))}
                    </div>
                )}

                {activeTab === 'societal-issues' && (
                    <div className="content-section">
                        <span className="underdevelopment-indicator">
                            This feature is under development and will be available soon!
                        </span>
                    </div>
                )}
            </div>
        </div>
    );
};

export default SearchResults; 