import React, { useState, useEffect } from 'react';
import { IconUserCircle, IconHeart, IconDotsVertical, IconEdit, IconTrash, IconShare2, IconMessageCircle } from '@tabler/icons-react';
import PropTypes from 'prop-types';
import './CommentBox.css';
import ReplyBox from './ReplyBox';

const CommentBox = ({ 
  onCommentCountChange, 
  onReplyCountChange,
  postedComments,
  setPostedComments,
  commentReplies,
  setCommentReplies 
}) => {
  const [commentText, setCommentText] = useState('');
  const [isEditing, setIsEditing] = useState(null);
  const [editedText, setEditedText] = useState('');
  const [showOptions, setShowOptions] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(null);
  const [showReplyInput, setShowReplyInput] = useState(null);
  const INITIAL_DISPLAY_COUNT = 5;
  const [displayCount, setDisplayCount] = useState(INITIAL_DISPLAY_COUNT);
  const [isExpanded, setIsExpanded] = useState(false);
  const [sortBy, setSortBy] = useState('newest');
  const [, setTimeUpdate] = useState(0);
  const [showShareModal, setShowShareModal] = useState({ isOpen: false, comment: null, shareText: '' });

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeUpdate(prev => prev + 1);
    }, 60000);

    return () => clearInterval(timer);
  }, []);

  const remainingComments = postedComments.length - displayCount;

  const handleLoadMore = () => {
    setDisplayCount(prev => prev + 5);
    setIsExpanded(true);
  };

  const handleShowLess = () => {
    setDisplayCount(INITIAL_DISPLAY_COUNT);
    setIsExpanded(false);
  };

  const sortComments = (comments, sortType) => {
    const sortedComments = [...comments];
    switch (sortType) {
      case 'newest':
        return sortedComments.sort((a, b) => 
          new Date(b.timestamp) - new Date(a.timestamp)
        );
      case 'oldest':
        return sortedComments.sort((a, b) => 
          new Date(a.timestamp) - new Date(b.timestamp)
        );
      case 'most_liked':
        return sortedComments.sort((a, b) => b.likes - a.likes);
      default:
        return sortedComments;
    }
  };

  const displayedComments = sortComments(postedComments, sortBy).slice(0, displayCount);

  const handleCommentSubmit = () => {
    if (!commentText.trim()) return;

    const newComment = {
      id: Date.now(),
      username: "Current User",
      profession: "Software Developer",
      timestamp: new Date().toISOString(),
      text: commentText,
      likes: 0,
      isLiked: false
    };

    setPostedComments(prev => [...prev, newComment]);
    setCommentText('');
    onCommentCountChange(prev => prev + 1);
  };

  const handleCommentEdit = (commentId) => {
    if (!editedText.trim()) return;
    setPostedComments(prev => prev.map(comment => 
      comment.id === commentId ? { ...comment, text: editedText } : comment
    ));
    setIsEditing(null);
    setEditedText('');
  };

  const handleCommentLike = (commentId) => {
    setPostedComments(prev => prev.map(comment => 
      comment.id === commentId ? {
        ...comment,
        likes: comment.isLiked ? comment.likes - 1 : comment.likes + 1,
        isLiked: !comment.isLiked
      } : comment
    ));
  };

  const handleCommentDelete = (commentId) => {
    setPostedComments(prev => prev.filter(comment => comment.id !== commentId));
    setShowDeleteModal(null);
    onCommentCountChange(prev => prev - 1);
  };

  const formatTimestamp = (timestamp) => {
    try {
      const date = new Date(timestamp);
      
      // Check if date is valid
      if (isNaN(date.getTime())) {
        return timestamp;
      }

      const now = new Date();
      const diffInSeconds = Math.floor((now - date) / 1000);

      if (diffInSeconds < 30) {
        return 'Just now';
      } else if (diffInSeconds < 60) {
        return 'Less than a minute ago';
      } else if (diffInSeconds < 3600) {
        const minutes = Math.floor(diffInSeconds / 60);
        return `${minutes} ${minutes === 1 ? 'minute' : 'minutes'} ago`;
      } else if (diffInSeconds < 86400) {
        const hours = Math.floor(diffInSeconds / 3600);
        return `${hours} ${hours === 1 ? 'hour' : 'hours'} ago`;
      } else if (diffInSeconds < 604800) { // Less than 7 days
        const days = Math.floor(diffInSeconds / 86400);
        return `${days} ${days === 1 ? 'day' : 'days'} ago`;
      } else {
        // Format date for older posts
        return date.toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'short',
          day: 'numeric'
        });
      }
    } catch (error) {
      return timestamp;
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const text = e.clipboardData.getData('text');
    const cleanText = text.replace(/\n+/g, ' ').trim();
    
    const start = e.target.selectionStart;
    const end = e.target.selectionEnd;
    const currentValue = commentText;
    const newValue = currentValue.substring(0, start) + cleanText + currentValue.substring(end);
    
    setCommentText(newValue);
    
    setTimeout(() => {
      e.target.selectionStart = e.target.selectionEnd = start + cleanText.length;
    }, 0);
  };

  const handleShare = async (comment) => {
    // Create a detailed share text
    const shareText = `${comment.username} (${comment.profession}) commented: "${comment.text}"\n\nShared from: ${window.location.origin}`;
    
    try {
      if (navigator.share) {
        // Mobile sharing
        await navigator.share({
          title: `Comment from ${comment.username}`,
          text: shareText,
          url: window.location.href
        });
      } else {
        // Desktop sharing - Show modal
        setShowShareModal({
          isOpen: true,
          comment: comment,
          shareText: shareText
        });
      }
    } catch (error) {
      console.error('Error sharing:', error);
    }
  };

  return (
    <div className={`comment-section ${showDeleteModal ? 'modal-open' : ''}`}>
      <div className="comments-header">
        <div className="sort-dropdown">
          <select 
            value={sortBy} 
            onChange={(e) => setSortBy(e.target.value)}
            className="sort-select"
          >
            <option value="newest">Newest First</option>
            <option value="oldest">Oldest First</option>
            <option value="most_liked">Most Liked</option>
          </select>
        </div>
      </div>

      <div className="comment-input-container">
        <textarea
          className="post-comment-textarea"
          placeholder="Write your comment..."
          value={commentText}
          onChange={(e) => {
            setCommentText(e.target.value);
            const textarea = e.target;
            textarea.style.height = 'auto';
            textarea.style.height = `${Math.min(textarea.scrollHeight, 100)}px`;
          }}
          onPaste={handlePaste}
        />
        <button 
          className="comment-button"
          onClick={handleCommentSubmit}
          disabled={!commentText.trim()}
        >
          Comment
        </button>
      </div>

      <div className="posted-comments">
        {displayedComments.map(comment => (
          <div key={comment.id}>
            <div className="comment-box">
              <div className="comment-header">
                <div className="comment-user">
                  <IconUserCircle size={35} />
                  <div className="user-info">
                    <h4>{comment.username}</h4>
                    <p className="comment-profession">{comment.profession}</p>
                  </div>
                </div>
                <div className="comment-meta">
                  <span className="comment-timestamp">{formatTimestamp(comment.timestamp)}</span>
                  <div className="comment-options">
                    <button 
                      className="options-toggle"
                      onClick={() => setShowOptions(showOptions === comment.id ? null : comment.id)}
                    >
                      <IconDotsVertical size={16} />
                    </button>
                    {showOptions === comment.id && (
                      <div className="options-menu">
                        <button onClick={() => {
                          setIsEditing(comment.id);
                          setEditedText(comment.text);
                          setShowOptions(null);
                        }}>
                          <IconEdit size={16} />
                          Edit
                        </button>
                        <button onClick={() => {
                          setShowDeleteModal(comment.id);
                          setShowOptions(null);
                        }}>
                          <IconTrash size={16} />
                          Delete
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="comment-content">
                {isEditing === comment.id ? (
                  <div className="comment-edit-container">
                    <textarea
                      value={editedText}
                      onChange={(e) => setEditedText(e.target.value)}
                      className="comment-edit-input"
                    />
                    <div className="edit-buttons">
                      <button
                        className="save-edit-btn"
                        onClick={() => handleCommentEdit(comment.id)}
                        disabled={!editedText.trim()}
                      >
                        Save
                      </button>
                      <button
                        className="cancel-edit-btn"
                        onClick={() => {
                          setIsEditing(null);
                          setEditedText('');
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                ) : (
                  <p>{comment.text}</p>
                )}
              </div>

              <div className="comment-actions">
                <button 
                  className={`comment-like-btn ${comment.isLiked ? 'liked' : ''}`}
                  onClick={() => handleCommentLike(comment.id)}
                >
                  <IconHeart size={16} />
                  <span>{comment.likes}</span>
                </button>
                <button 
                  className={`comment-reply-btn ${showReplyInput === comment.id ? 'active' : ''}`}
                  onClick={() => setShowReplyInput(showReplyInput === comment.id ? null : comment.id)}
                >
                  <IconMessageCircle size={16} />
                  <span>{(commentReplies[comment.id] || []).length}</span>
                </button>
                <button 
                  className="comment-share-btn"
                  onClick={() => handleShare(comment)}
                >
                  <IconShare2 size={16} />
                </button>
              </div>

              <ReplyBox
                commentId={comment.id}
                onReplySubmit={(commentId, replyText) => {
                  const newReply = {
                    id: Date.now(),
                    username: "Current User",
                    profession: "Software Developer",
                    timestamp: new Date().toISOString(),
                    text: replyText,
                    likes: 0,
                    isLiked: false
                  };
                  setCommentReplies(prev => ({
                    ...prev,
                    [commentId]: [...(prev[commentId] || []), newReply]
                  }));
                  onReplyCountChange(prev => prev + 1);
                }}
                onReplyCountChange={onReplyCountChange}
                replies={commentReplies[comment.id] || []}
                setCommentReplies={setCommentReplies}
                isVisible={showReplyInput === comment.id}
              />
            </div>

            {showDeleteModal === comment.id && (
              <>
                <div 
                  className="delete-modal-overlay"
                  onClick={() => setShowDeleteModal(null)}
                />
                <div className="delete-modal">
                  <p>Are you sure you want to delete this comment?</p>
                  <div className="delete-modal-buttons">
                    <button onClick={() => setShowDeleteModal(null)}>Cancel</button>
                    <button 
                      className="delete-confirm" 
                      onClick={() => handleCommentDelete(comment.id)}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        ))}
        
        <div className="comments-toggle-buttons">
          {remainingComments > 0 && (
            <button 
              className="load-more-comments"
              onClick={handleLoadMore}
            >
              View {remainingComments} more comments
            </button>
          )}
          
          {isExpanded && displayCount > INITIAL_DISPLAY_COUNT && (
            <button 
              className="show-less-comments"
              onClick={handleShowLess}
            >
              Show less
            </button>
          )}
        </div>
      </div>

      {showShareModal.isOpen && (
        <div className="share-modal">
          <div className="share-modal-content">
            <h3>Share this comment</h3>
            <div className="share-options">
              <button onClick={() => window.open(`https://twitter.com/intent/tweet?text=${encodeURIComponent(showShareModal.shareText)}`)}>
                Share on Twitter
              </button>
              <button onClick={() => window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}`)}>
                Share on Facebook
              </button>
              <button onClick={() => window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(window.location.href)}`)}>
                Share on LinkedIn
              </button>
              <button onClick={() => window.open(`mailto:?subject=Check out this comment&body=${encodeURIComponent(showShareModal.shareText)}`)}>
                Share via Email
              </button>
              <button onClick={async () => {
                await navigator.clipboard.writeText(showShareModal.shareText);
                alert('Copied to clipboard!');
              }}>
                Copy to Clipboard
              </button>
            </div>
            <button 
              className="close-modal-btn"
              onClick={() => setShowShareModal({ isOpen: false, comment: null, shareText: '' })}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

CommentBox.propTypes = {
  onCommentCountChange: PropTypes.func.isRequired,
  onReplyCountChange: PropTypes.func.isRequired,
  postedComments: PropTypes.array.isRequired,
  setPostedComments: PropTypes.func.isRequired,
  commentReplies: PropTypes.object.isRequired,
  setCommentReplies: PropTypes.func.isRequired,
};

export default CommentBox;