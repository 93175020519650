import React, { useState, useEffect } from 'react';
import { IconUserCircle, IconHeart, IconDotsVertical, IconEdit, IconTrash, IconShare2 } from '@tabler/icons-react';
import PropTypes from 'prop-types';
import './ReplyBox.css';

const ReplyBox = ({
  commentId,
  onReplySubmit,
  onReplyCountChange,
  replies,
  setCommentReplies,
  isVisible
}) => {
  const [isEditing, setIsEditing] = useState(null);
  const [editedReplyText, setEditedReplyText] = useState('');
  const [showOptions, setShowOptions] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(null);
  const [replyLikes, setReplyLikes] = useState({});
  const [, setTimeUpdate] = useState(0);
  const [showAllReplies, setShowAllReplies] = useState(false);
  const INITIAL_REPLIES_SHOWN = 3;
  const [showShareModal, setShowShareModal] = useState({ isOpen: false, reply: null, shareText: '' });
  const [replyText, setReplyText] = useState('');

  useEffect(() => {
    if (!isVisible) {
      setShowAllReplies(false);
    }
  }, [isVisible]);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeUpdate(prev => prev + 1);
    }, 60000);

    return () => clearInterval(timer);
  }, []);

  if (!isVisible) return null;

  const formatTimestamp = (timestamp) => {
    try {
      const date = new Date(timestamp);
      
      // Check if date is valid
      if (isNaN(date.getTime())) {
        return timestamp;
      }

      const now = new Date();
      const diffInSeconds = Math.floor((now - date) / 1000);

      if (diffInSeconds < 30) {
        return 'Just now';
      } else if (diffInSeconds < 60) {
        return 'Less than a minute ago';
      } else if (diffInSeconds < 3600) {
        const minutes = Math.floor(diffInSeconds / 60);
        return `${minutes} ${minutes === 1 ? 'minute' : 'minutes'} ago`;
      } else if (diffInSeconds < 86400) {
        const hours = Math.floor(diffInSeconds / 3600);
        return `${hours} ${hours === 1 ? 'hour' : 'hours'} ago`;
      } else if (diffInSeconds < 604800) { // Less than 7 days
        const days = Math.floor(diffInSeconds / 86400);
        return `${days} ${days === 1 ? 'day' : 'days'} ago`;
      } else {
        return date.toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'short',
          day: 'numeric'
        });
      }
    } catch (error) {
      return timestamp;
    }
  };

  const handleReplyEdit = (replyId) => {
    if (!editedReplyText.trim()) return;
    
    setCommentReplies(prev => ({
      ...prev,
      [commentId]: prev[commentId].map(reply => 
        reply.id === replyId 
          ? { ...reply, text: editedReplyText }
          : reply
      )
    }));

    setIsEditing(null);
    setEditedReplyText('');
  };

  const handleReplyLike = (replyId) => {
    setReplyLikes(prev => ({
      ...prev,
      [replyId]: !prev[replyId]
    }));

    setCommentReplies(prev => ({
      ...prev,
      [commentId]: prev[commentId].map(reply => 
        reply.id === replyId ? {
          ...reply,
          likes: reply.likes !== undefined ? 
            (!replyLikes[replyId] ? reply.likes + 1 : reply.likes - 1) : 
            (!replyLikes[replyId] ? 1 : 0),
          isLiked: !replyLikes[replyId]
        } : reply
      )
    }));
  };

  const handleReplyDelete = (replyId) => {
    setCommentReplies(prev => ({
      ...prev,
      [commentId]: prev[commentId].filter(reply => reply.id !== replyId)
    }));
    
    onReplyCountChange(prev => prev - 1);
    setShowDeleteModal(null);
  };

  const handleShare = async (reply) => {
    // Create a more detailed share text
    const shareText = `${reply.username} (${reply.profession}) replied: "${reply.text}"\n\nShared from: ${window.location.origin}`;
    
    try {
      if (navigator.share) {
        // Mobile sharing
        await navigator.share({
          title: `Reply from ${reply.username}`,
          text: shareText,
          url: window.location.href
        });
      } else {
        // Desktop sharing - Show a share modal
        setShowShareModal({
          isOpen: true,
          reply: reply,
          shareText: shareText
        });
      }
    } catch (error) {
      console.error('Error sharing:', error);
    }
  };

  const handleReplySubmit = () => {
    onReplySubmit(commentId, replyText);
    setReplyText('');
  };

  // Sort replies by timestamp (newest first)
  const sortedReplies = [...replies].sort((a, b) => 
    new Date(b.timestamp) - new Date(a.timestamp)
  );

  // Use sortedReplies instead of replies for display
  const displayedReplies = showAllReplies 
    ? sortedReplies 
    : sortedReplies.slice(0, INITIAL_REPLIES_SHOWN);

  const remainingReplies = sortedReplies.length - INITIAL_REPLIES_SHOWN;

  return (
    <div className="reply-section">
      <div className="reply-input">
        <div className="reply-input-field">
          <textarea
            placeholder="Write a reply..."
            value={replyText}
            onChange={(e) => setReplyText(e.target.value)}
            className="reply-textarea"
          />
          <button
            className="post-reply-button"
            onClick={handleReplySubmit}
            disabled={!replyText.trim()}
          >
            Reply
          </button>
        </div>
      </div>

      {sortedReplies.length > 0 && (
        <div className="replies-section">
          {displayedReplies.map(reply => (
            <div key={reply.id} className="reply-box">
              <div className="reply-header">
                <div className="reply-user">
                  <IconUserCircle size={32} />
                  <div className="user-info">
                    <h4>{reply.username}</h4>
                    <p className="reply-profession">{reply.profession}</p>
                  </div>
                </div>
                <div className="reply-header-right">
                  <span className="reply-timestamp">{formatTimestamp(reply.timestamp)}</span>
                  <div className="reply-options">
                    <button 
                      className="options-toggle"
                      onClick={() => setShowOptions(showOptions === reply.id ? null : reply.id)}
                    >
                      <IconDotsVertical size={16} />
                    </button>
                    {showOptions === reply.id && (
                      <div className="options-menu">
                        <button onClick={() => {
                          setIsEditing(reply.id);
                          setEditedReplyText(reply.text);
                          setShowOptions(null);
                        }}>
                          <IconEdit size={16} />
                          Edit
                        </button>
                        <button onClick={() => {
                          setShowDeleteModal(reply.id);
                          setShowOptions(null);
                        }}>
                          <IconTrash size={16} />
                          Delete
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="reply-content">
                {isEditing === reply.id ? (
                  <div className="reply-edit-container">
                    <textarea
                      value={editedReplyText}
                      onChange={(e) => setEditedReplyText(e.target.value)}
                      className="reply-edit-input"
                    />
                    <div className="edit-buttons">
                      <button
                        className="save-edit-btn"
                        onClick={() => handleReplyEdit(reply.id)}
                        disabled={!editedReplyText.trim()}
                      >
                        Save
                      </button>
                      <button
                        className="cancel-edit-btn"
                        onClick={() => {
                          setIsEditing(null);
                          setEditedReplyText('');
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                ) : (
                  <p>{reply.text}</p>
                )}
              </div>

              <div className="reply-actions">
                <button 
                  className={`reply-like-btn ${replyLikes[reply.id] ? 'liked' : ''}`}
                  onClick={() => handleReplyLike(reply.id)}
                >
                  <IconHeart size={16} />
                  <span>{reply.likes}</span>
                </button>
                <button className="reply-share-btn" onClick={() => handleShare(reply)}>
                  <IconShare2 size={16} />
                </button>
              </div>

              {showDeleteModal === reply.id && (
                <div className="delete-modal">
                  <p>Are you sure you want to delete this reply?</p>
                  <div className="delete-modal-buttons">
                    <button onClick={() => setShowDeleteModal(null)}>Cancel</button>
                    <button 
                      className="delete-confirm"
                      onClick={() => handleReplyDelete(reply.id)}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              )}
            </div>
          ))}
          
          {!showAllReplies && remainingReplies > 0 && (
            <button 
              className="show-more-replies"
              onClick={() => setShowAllReplies(true)}
            >
              Show {remainingReplies} more {remainingReplies === 1 ? 'reply' : 'replies'}
            </button>
          )}
          
          {showAllReplies && sortedReplies.length > INITIAL_REPLIES_SHOWN && (
            <button 
              className="show-less-replies"
              onClick={() => setShowAllReplies(false)}
            >
              Show less
            </button>
          )}
        </div>
      )}
      {showShareModal.isOpen && (
        <div className="share-modal">
          <div className="share-modal-content">
            <h3>Share this reply</h3>
            <div className="share-options">
              <button onClick={() => window.open(`https://twitter.com/intent/tweet?text=${encodeURIComponent(showShareModal.shareText)}`)}>
                Share on Twitter
              </button>
              <button onClick={() => window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}`)}>
                Share on Facebook
              </button>
              <button onClick={() => window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(window.location.href)}`)}>
                Share on LinkedIn
              </button>
              <button onClick={() => window.open(`mailto:?subject=Check out this reply&body=${encodeURIComponent(showShareModal.shareText)}`)}>
                Share via Email
              </button>
              <button onClick={async () => {
                await navigator.clipboard.writeText(showShareModal.shareText);
                alert('Copied to clipboard!');
              }}>
                Copy to Clipboard
              </button>
            </div>
            <button 
              className="close-modal-btn"
              onClick={() => setShowShareModal({ isOpen: false, reply: null, shareText: '' })}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

ReplyBox.propTypes = {
  commentId: PropTypes.number.isRequired,
  onReplySubmit: PropTypes.func.isRequired,
  onReplyCountChange: PropTypes.func.isRequired,
  replies: PropTypes.array.isRequired,
  setCommentReplies: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
};

export default ReplyBox;
