import { COUNTRY_CODES } from './countryData';

// Constants for validation rules
const VALIDATION_RULES = {
    NAME: {
        MIN_LENGTH: 2,
        MAX_LENGTH: 50,
        BANNED_KEYWORDS: ['admin', 'root', 'system', 'null', 'undefined'],
        MAX_REPEATING_CHARS: 3
    },
    PHONE: {
        // Remove the conflicting rules, since we're using COUNTRY_CODES
    },
    EMAIL: {
        MAX_LENGTH: 254,
        MIN_DOMAIN_SEGMENTS: 2,
        BANNED_DOMAINS: ['tempmail.com', 'throwaway.com'],
        ALLOWED_TLDS: [
            // Generic TLDs
            'com', 'net', 'org', 'edu', 'gov', 'mil', 'int',
            // Country code TLDs
            'uk', 'us', 'ca', 'au', 'de', 'fr', 'jp', 'cn', 'in', 'br',
            // New generic TLDs
            'info', 'biz', 'io', 'dev', 'co', 'ai', 'app', 'tech',
            // Add more as needed
        ],
    }
};

// Security utility functions
const sanitizeInput = (input) => {
    if (typeof input !== 'string') return '';
    
    return input
        .replace(/[<>{}()`'"]/g, '')
        .replace(/(\b)(on\S+)(\s*)=/g, '')
        .replace(/(javascript|vbscript|expression|script)/gi, '')
        .trim();
};

const containsSuspiciousPatterns = (input) => {
    const suspiciousPatterns = [
        /(\b)(select|insert|update|delete|drop|union|exec|eval)(\b)/gi,
        /<[^>]*>/,
        /\${.*}/,
        /((%)27|('')).+((%)6F|o|(%)4F).+((%)72|r|(%)52)/,
        /\.\.\/|\/\/|\\\\/,
    ];
    
    return suspiciousPatterns.some(pattern => pattern.test(input));
};

// Enhanced name validation
export const validateName = (name) => {
    const cleanName = sanitizeInput(name).replace(/\s+/g, ' ');
    
    if (!cleanName || typeof cleanName !== 'string') {
        return {
            isValid: false,
            error: 'Invalid name format'
        };
    }

    if (cleanName.length < VALIDATION_RULES.NAME.MIN_LENGTH || 
        cleanName.length > VALIDATION_RULES.NAME.MAX_LENGTH) {
        return {
            isValid: false,
            error: `Name must be between ${VALIDATION_RULES.NAME.MIN_LENGTH} and ${VALIDATION_RULES.NAME.MAX_LENGTH} characters`
        };
    }

    if (containsSuspiciousPatterns(cleanName)) {
        return {
            isValid: false,
            error: 'Invalid characters detected'
        };
    }

    if (VALIDATION_RULES.NAME.BANNED_KEYWORDS.some(keyword => 
        cleanName.toLowerCase().includes(keyword))) {
        return {
            isValid: false,
            error: 'Name contains prohibited words'
        };
    }

    const nameRegex = /^[a-zA-ZÀ-ÿ\s'-]+$/;
    if (!nameRegex.test(cleanName)) {
        return {
            isValid: false,
            error: 'Name can only contain letters, spaces, hyphens, and apostrophes'
        };
    }

    if (new RegExp(`(.)\\1{${VALIDATION_RULES.NAME.MAX_REPEATING_CHARS},}`).test(cleanName)) {
        return {
            isValid: false,
            error: 'Name contains too many repeated characters'
        };
    }

    const normalizedName = cleanName.normalize('NFKC');

    return {
        isValid: true,
        value: normalizedName
    };
};

// Enhanced phone validation
export const validatePhone = (phoneString, selectedCountry) => {
    if (!phoneString) {
        return { isValid: false, error: 'Phone number is required', value: '' };
    }

    // Find the country data for the selected country
    const countryData = COUNTRY_CODES.find(c => c.country === selectedCountry);
    if (!countryData) {
        return { 
            isValid: false, 
            error: 'Please select a valid country', 
            value: '' 
        };
    }

    // Get the number part without the country code
    const numberPart = phoneString.slice(countryData.code.length);

    // Validate number length based on country requirements
    if (numberPart.length < countryData.minLength || numberPart.length > countryData.maxLength) {
        return { 
            isValid: false, 
            error: `Phone number must be ${countryData.minLength} digits for ${countryData.country}`, 
            value: '' 
        };
    }

    // US/Canada specific area code validation could be added here if needed
    
    return { isValid: true, error: '', value: phoneString };
};

// Enhanced email validation
export const validateEmail = (email) => {
    const cleanEmail = sanitizeInput(email).toLowerCase();

    // Basic format check
    const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    
    if (!emailRegex.test(cleanEmail)) {
        return {
            isValid: false,
            error: 'Invalid email format'
        };
    }

    // TLD validation
    const tld = cleanEmail.split('.').pop();
    if (!VALIDATION_RULES.EMAIL.ALLOWED_TLDS.includes(tld)) {
        return {
            isValid: false,
            error: 'Invalid or unsupported email domain'
        };
    }

    if (cleanEmail.length > VALIDATION_RULES.EMAIL.MAX_LENGTH) {
        return {
            isValid: false,
            error: 'Email is too long'
        };
    }

    const [, domain] = cleanEmail.split('@');
    const domainParts = domain.split('.');

    if (domainParts.length < VALIDATION_RULES.EMAIL.MIN_DOMAIN_SEGMENTS) {
        return {
            isValid: false,
            error: 'Invalid domain format'
        };
    }

    if (VALIDATION_RULES.EMAIL.BANNED_DOMAINS.includes(domain)) {
        return {
            isValid: false,
            error: 'This email domain is not allowed'
        };
    }

    if (containsSuspiciousPatterns(cleanEmail)) {
        return {
            isValid: false,
            error: 'Invalid email format detected'
        };
    }

    return {
        isValid: true,
        value: cleanEmail
    };
};
