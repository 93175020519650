import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './UserProfile2.css';
import { IconUserCircle, IconUserPlus } from '@tabler/icons-react';
import { Card, CardHeader, CardContent, Button } from '@mui/material'; // Ensure you have @mui/material installed

const UserProfile2 = () => {
    const location = useLocation();
    const person = location.state?.person;

    const [isAddFriendPopupOpen, setIsAddFriendPopupOpen] = useState(false);
    const [name, setName] = useState(person?.name || "Raz Conteh");
    const [jobTitle, setJobTitle] = useState(person?.profession || "Cancer Researcher");
    const [vision] = useState("But I must explain to you how all this mistaken idea of denouncing pleasure...");

    useEffect(() => {
        if (person) {
            setName(person.name);
            setJobTitle(person.profession);
        }
    }, [person]);

    const handleAddFriendClick = () => {
        setIsAddFriendPopupOpen(true);
    };

    const handleCloseAddFriendPopup = () => {
        setIsAddFriendPopupOpen(false);
    };

    // Effect to handle body scroll
    useEffect(() => {
        if (isAddFriendPopupOpen) {
            document.body.style.overflow = 'hidden'; // Disable scrolling
        } else {
            document.body.style.overflow = 'unset'; // Enable scrolling
        }
    }, [isAddFriendPopupOpen]);

    return (
        <div className="profile-container">
            <div className="cover-image"></div>
            <div className="profile-section">
                <div className="user-avatar">
                    <IconUserCircle className='user-avatar' />
                </div>
                <div className="user-info">
                    <h2 className="user-name">{name}</h2>
                    <p className="job-title">{jobTitle}</p>
                    <button className="add-friend-button" onClick={handleAddFriendClick}>
                        <IconUserPlus /> Add Friend
                    </button>
                </div>
                <div className="stats-section">
                    <div className="stat-box">
                        <span className="stat-label">Followers</span>
                        <span className="stat-number">48</span>
                    </div>
                    <div className="stat-box">
                        <span className="stat-label">Skills</span>
                        <span className="stat-number">3</span>
                    </div>
                    <div className="stat-box">
                        <span className="stat-label">Projects</span>
                        <span className="stat-number">15</span>
                    </div>
                </div>
            </div>
            <div className="vision-section">
                <h2 className="vision-title">My Vision</h2>
                <p className="vision-text">{vision}</p>
            </div>

            {isAddFriendPopupOpen && (
                <div className="popup-overlay">
                    <div className="popup">
                        <Card className="popup-card">
                            <CardHeader
                                title="Add Friend"
                                action={<Button onClick={handleCloseAddFriendPopup}>Close</Button>}
                            />
                            <CardContent>
                                <div className="cover-image-pop">
                                    <div className="avatar-popup">
                                        <IconUserCircle />
                                    </div>
                                </div>
                                <div className="user-details">
                                    <div className="input-container">
                                        <span>Would you like to add {name} as a friend?</span>
                                    </div>
                                    <div className="button-container">
                                        <Button variant="contained" color="primary" onClick={handleCloseAddFriendPopup}>
                                            Add Friend
                                        </Button>
                                        <Button variant="outlined" onClick={handleCloseAddFriendPopup}>
                                            Cancel
                                        </Button>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                    </div>
                </div>
            )}
        </div>
    );
};

export default UserProfile2;